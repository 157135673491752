import {

  baseMockEnvironment
} from '@bmw-spp/bmw-spp-frontend-common';
import { IAstEnvironment } from './ienvironment';

export const environment: IAstEnvironment = {
  ...baseMockEnvironment,
  environmentName: 'Mock',
  apiUrl: 'https://www.myurl.invalid/',
  providers: [...baseMockEnvironment.providers,

  ],
  authEnabled: false,
  clientId: 'c2983a8f-ca78-4bd3-b923-940bd716e50a',
  issuer: 'https://auth-i.bmwgroup.net:443/auth/oauth2/realms/root/realms/internetb2xmfaonly',
  secureRouteRootUrl: 'https://spp-backend.c2-cloud.de/ast/',
  redirectURL: 'https://spp-ast.c2-cloud.de/',
};
