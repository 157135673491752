import { Component, inject, OnInit, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  DsHeaderModule, DsBoxModule, DsButtonModule, DsNavigationBarModule, DsHeaderTagConfiguration, DsNavigationItem,
  DsListItemModule,
  DsFormFieldModule,
  DsSelectModule,
  SelectListOption
} from '@bmw-ds/components';
import { environment } from '../environments/environment';
import debug from 'debug';
import { SecurityService, TenantService, UserData } from '@bmw-spp/bmw-spp-frontend-common';
import { firstValueFrom } from 'rxjs';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    DsHeaderModule,
    DsBoxModule,
    DsButtonModule,
    DsNavigationBarModule,
    DsListItemModule,
    DsFormFieldModule,
    DsSelectModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  private logDebug = debug('app:AppComponent:log');
  private logError = debug('app:AppComponent:error*');
  tenantOptions: SelectListOption[] = [];

  //Tag with environment for header
  environmentTagConfig: Partial<DsHeaderTagConfiguration> = { label: environment.environmentName };
  userInfo = {
    username: 'John Doe',
    department: 'Dept 1',
    profileImg: 'https://www.gravatar.com/avatar/?d=identicon'
  };


  //Navigation configuration
  navigationItems: DsNavigationItem[] = [
  ];

  title = 'Asset Table';

  private securityService: SecurityService = inject(SecurityService);
  private tenantService: TenantService = inject(TenantService);


  constructor() {
    this.logDebug.log = console.log.bind(console);
    this.logError.log = console.error.bind(console);
  }

  public async ngOnInit(): Promise<void> {
    if (!environment.authEnabled) {
      this.logDebug('Auth is disabled');
      return;
    }

    this.logDebug('Checking auth');

    try {
    
      const result = await firstValueFrom(this.securityService.checkAuth());
      this.logDebug('Login result: %O', result);

      if (!result.isAuthenticated) {
        this.logDebug('User is not authenticated. Starting authorization...');
        this.securityService.authorize();
        return;
      }
      const userInfo: UserData = await firstValueFrom(this.securityService.getUserData());
      if (!userInfo) {
        return;
      }

      const groups = userInfo['groups'] || [];
      await this.tenantService.loadTenants({ groups });

      this.userInfo = {
        username: `${userInfo.given_name} ${userInfo.family_name}`,
        department: userInfo.departmentnumber ?? '',
        profileImg: userInfo.profileImg ?? '',
      };

      const tenants = this.tenantService.availableTenants();
      if (tenants && tenants.length > 0) {
        this.tenantOptions = tenants.map((group: string) => ({
          id: group,
          label: group,
          value: group,
        }));

        this.handleTenantSelection({
          id: localStorage.getItem('selectedTenant') ?? this.tenantOptions[0].id,
          label: localStorage.getItem('selectedTenant') ?? this.tenantOptions[0].id
        });
        this.dsSelectDefaultValue.set(localStorage.getItem('selectedTenant') ?? this.tenantOptions[0].id);
      }

      this.logDebug('User info updated: %O', this.userInfo);
    } catch (error) {
      this.logError('Error during auth check: %O', error);
    }
  }


  public dsSelectDefaultValue = signal('')


  public selectedTenant = signal('');

  private setEnvironmentTag() {
    this.environmentTagConfig = {
      label: `${environment.environmentName} - ${this.tenantService.selectedTenant() ? this.tenantService.selectedTenant() : 'Please select a tenant'}`,
    };
  }

  public handleTenantSelection(event: SelectListOption | SelectListOption[] | null) {
    if (event && !Array.isArray(event)) {
      if (event.id !== this.tenantService.selectedTenant()) {
        this.tenantService.setSelectedTenant(event.id);
      }
      this.setEnvironmentTag();
    }
  }
}
